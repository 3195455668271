<template>
  <div class="statements">
    <validation-observer ref="statementObserver">
      <ValidationProvider
        v-for="statement in statements"
        :key="statement.promptId"
        :rules="statement.isRequired ? 'statementRequired' : ''"
        v-slot="{ errors }"
        :name="statement.promptIdentifier"
      >
        <b-field
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="my-4"
        >
          <b-checkbox
            :id="`statementAcceptanceCheckbox_${statement.promptId}`"
            class="af-checkbox"
            :name="statement.promptIdentifier"
            :class="{ 'has-text-danger': errors[0] }"
            :disabled="
              statement.promptMustRead && !openedPrompts[statement.promptId]
            "
            v-model="statementValueBucket[statement.promptId]"
            @click.native="showOpenError(statement.promptId)"
            @input="saveAcceptance($event, statement)"
          />
          <span>
            <span
              v-html="
                statement.type === STATEMENT_PROMPT_TYPE.LINK
                  ? processLinks(statement.promptLabel)
                  : statement.promptLabel
              "
            />

            <StatementReaderModal
              v-if="statement.type === STATEMENT_PROMPT_TYPE.DOCUMENT"
              :documentId="statement.promptDocument"
              :isPublic="isPublic"
              @accept="setPromptOpen(statement.promptId)"
            />

            <StatementLink
              v-if="statement.type === STATEMENT_PROMPT_TYPE.LINK"
              :link="statement.link"
              @accept="setPromptOpen(statement.promptId)"
            />
          </span>
        </b-field>

        <p
          v-if="
            statement.promptMustRead && showErrorForPrompts[statement.promptId]
          "
          class="help is-danger mb-4"
        >
          {{ $t(readRequiredValidationMessage) }}
        </p>
      </ValidationProvider>
    </validation-observer>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { STATEMENT_PROMPT_TYPE } from "@/utils/const";

export default {
  name: "StatementsAcceptance",
  components: {
    StatementReaderModal: () => import("@/components/StatementReaderModal.vue"),
    StatementLink: () => import("@/components/StatementLink.vue"),
  },
  mounted() {
    this.initValueBucket();
  },
  watch: {
    statements() {
      this.initValueBucket();
    },
  },
  props: {
    statements: {
      type: Array,
      required: true,
    },
    layoutType: {
      type: String,
      required: true,
    },
    readRequiredValidationMessage: {
      type: String,
      required: true,
    },
    isPublic: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      STATEMENT_PROMPT_TYPE,
      statementValueBucket: {},
      openedPrompts: {},
      showErrorForPrompts: {},
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "session/isReady",
      userId: "session/userId",
    }),
  },
  methods: {
    processLinks(text) {
      const regex = /\{\{([^|]+)\|([^}]+)\}\}/g;
      return text.replace(regex, '<a href="$1" target="_blank">$2</a>');
    },
    initValueBucket() {
      if (this.statements) {
        this.statements.forEach((ac) => {
          this.statementValueBucket[ac.promptId] = false;
        });
      }
    },
    setPromptOpen(promptId) {
      Vue.set(this.openedPrompts, promptId, true);
      Vue.set(this.showErrorForPrompts, promptId, false);
    },
    showOpenError(promptId) {
      if (!this.openedPrompts[promptId]) {
        Vue.set(this.showErrorForPrompts, promptId, true);
      }
    },
    async validate() {
      return await this.$refs.statementObserver.validate();
    },
    async saveAcceptance(checked, acceptance) {
      if (this.loggedIn) {
        try {
          let request = {
            userId: this.userId,
            statementId: acceptance.promptId,
            statementIdentifier: acceptance.promptIdentifier,
            statementLabel: acceptance.promptLabel,
            documentId:
              acceptance.type === STATEMENT_PROMPT_TYPE.DOCUMENT
                ? acceptance.promptDocument
                : null,
            link:
              acceptance.type === STATEMENT_PROMPT_TYPE.LINK
                ? acceptance.link
                : null,
            isDynamicStatement: false,
            isRequired: !!acceptance.isRequired,
            isAccepted: checked,
            layoutType: this.layoutType,
            flowIdentifier: null,
          };
          await this.$store.dispatch(
            "acceptanceRequirements/acceptStatement",
            request
          );
        } catch (ex) {
          console.error("Statement Acceptance save failed!");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.statements {
  max-height: 9.5rem !important;
  overflow-y: auto;
}
</style>
