var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statements"},[_c('validation-observer',{ref:"statementObserver"},_vm._l((_vm.statements),function(statement){return _c('ValidationProvider',{key:statement.promptId,attrs:{"rules":statement.isRequired ? 'statementRequired' : '',"name":statement.promptIdentifier},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"my-4",attrs:{"type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-checkbox',{staticClass:"af-checkbox",class:{ 'has-text-danger': errors[0] },attrs:{"id":("statementAcceptanceCheckbox_" + (statement.promptId)),"name":statement.promptIdentifier,"disabled":statement.promptMustRead && !_vm.openedPrompts[statement.promptId]},on:{"input":function($event){return _vm.saveAcceptance($event, statement)}},nativeOn:{"click":function($event){return _vm.showOpenError(statement.promptId)}},model:{value:(_vm.statementValueBucket[statement.promptId]),callback:function ($$v) {_vm.$set(_vm.statementValueBucket, statement.promptId, $$v)},expression:"statementValueBucket[statement.promptId]"}}),_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(
              statement.type === _vm.STATEMENT_PROMPT_TYPE.LINK
                ? _vm.processLinks(statement.promptLabel)
                : statement.promptLabel
            )}}),(statement.type === _vm.STATEMENT_PROMPT_TYPE.DOCUMENT)?_c('StatementReaderModal',{attrs:{"documentId":statement.promptDocument,"isPublic":_vm.isPublic},on:{"accept":function($event){return _vm.setPromptOpen(statement.promptId)}}}):_vm._e(),(statement.type === _vm.STATEMENT_PROMPT_TYPE.LINK)?_c('StatementLink',{attrs:{"link":statement.link},on:{"accept":function($event){return _vm.setPromptOpen(statement.promptId)}}}):_vm._e()],1)],1),(
          statement.promptMustRead && _vm.showErrorForPrompts[statement.promptId]
        )?_c('p',{staticClass:"help is-danger mb-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.readRequiredValidationMessage))+" ")]):_vm._e()]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }